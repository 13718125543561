import React from "react";
import Usuarios from "../components/usuario/Usuarios";

const PageUsuarios = (props) => {

  document.title = "Pollaris ERP - Usuários";

  return (<div className="container" >
    <Usuarios />
  </div >);
};

export default PageUsuarios;