
import moment from "moment";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";
import { Modal, ModalBody } from "react-bootstrap";
import FormProduto from "./FormProduto";
import ToastService from "../../services/ToastService";

const Produtos = ({ titulo, categoria }) => {

    let loaded = false;

    const [loading, setLoading] = useState(false);

    const [dataDe, setDataDe] = useState(moment().format("YYYY-MM-01"));

    const [dataAte, setDataAte] = useState(moment().format("YYYY-MM-DD"));

    const [produtos, setProdutos] = useState([]);

    const [produtoSelecionado, setProdutoSelecionado] = useState({});

    const [showModal, setShowModal] = useState(false);

    const pollarisApi = new PollarisApi();

    const toastService = new ToastService();

    const loadProdutos = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        pollarisApi.get("produtos")
            .then(response => {
                setLoading(false);
                setProdutos(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );

    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadProdutos();

    }, []);


    const editarProduto = (produto) => {
        setProdutoSelecionado(produto);
        setShowModal(true);
    }

    const desativarProduto = (produto) => {

        produto.ativo = false;
        pollarisApi
            .post(`produtos`, produto)
            .then(() => {
                loadProdutos()
                toastService.success("Sucesso..", "Produto desativado com sucesso...");
            })
            .catch(() => toastService.error("Erro ao desativar produto", "Ocorreu um erro ao desativar o produto. Tente novamente mais tarde."));
    }

    return (
        <div className="produtos">
            <div className="card shadow">
                <div className="card-body">
                    <h5>{titulo ? titulo : "Produtos"}
                        <button onClick={() => editarProduto({})} className="btn float-end">
                            + Novo
                        </button>
                    </h5>
                    <hr />
                    {produtos.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Sistema</th>
                                        <th>Nome</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {produtos.map(produto => (
                                        <tr key={produto.id}>
                                            <td>{produto.id}</td>
                                            <td>{produto.programa}</td>
                                            <td>{produto.nome}</td>
                                            <td>
                                                <button onClick={() => editarProduto(produto)} className="btn btn-outline-primary btn-sm">
                                                    <i className="bi bi-pencil"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => desativarProduto(produto)} className="btn btn-outline-danger btn-sm">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhum produto cadastrado.
                        </div>
                    }

                </div>
            </div>

            <Modal show={showModal} onExit={() => loadProdutos()} onHide={() => setShowModal(false)} dialogClassName="modal-lg">
                <ModalBody>
                    <div className="mb-3">
                        <h5>
                            {produtoSelecionado ? "Editar" : "Novo"} produto
                            <button className="btn btn-close float-end" onClick={() => setShowModal(false)}></button>
                        </h5>

                    </div>
                    <FormProduto onSave={() => setShowModal(false)} produtoSelecionado={produtoSelecionado} />
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Produtos;