
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";
import FormDespesa from "./FormDespesa";
import ToastService from "../../services/ToastService";

const Despesas = () => {

    let loaded = false;

    const [loading, setLoading] = useState(false);

    const [despesa, setDespesa] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const periodicidades = ["Nenhum", "Mensal", "Anual"];

    const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", 'Setembro', "Outubro", "Novembro", "Dezembro"];

    const [despesas, setDespesas] = useState([]);

    const pollarisApi = new PollarisApi();

    const toastService = new ToastService();

    const selecionarDespesa = (despesa) => {

        setDespesa(despesa);

        setShowModal(true);
    }

    const deletarDespesa = (despesa) => {

        setLoading(true);

        pollarisApi
            .delete(`despesas/${despesa.id}`)
            .then(() => {

                setLoading(false);

                setDespesas(despesas.filter(d => d.id !== despesa.id));

                toastService.success("Sucesso..", "Despesa deletada com sucesso");

            }).catch(() => {

                setLoading(false);

                toastService.error("Erro ao deletar despesa", "Erro ao deletar despesa");
            }).finally(() => {
                setLoading(false);
            }
            );
    }


    const loadDespesas = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        let request = `despesas`;

        pollarisApi.get(request)
            .then(response => {
                setLoading(false);
                setDespesas(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );
    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadDespesas();

    }, []);

    const pagar = (despesa) => {

        var dataVencimento = moment(despesa.vencimento);

        var nomeBrMesDespesa = meses[dataVencimento.month()];

        if (despesa.periodicidade === 2) {
            return "Todo ano no mês de " + nomeBrMesDespesa;
        } else if (despesa.periodicidade === 1) {
            return "Todo mês no dia " + dataVencimento.format("DD");
        } else {
            return dataVencimento.format("DD/MM/YYYY");
        }
    }

    return (
        <div className="cobrancas">
            <div className="card shadow">
                <div className="card-body">
                    <div>
                        <h5>
                            Despesas
                            <button className="btn float-end" title="Editar despesa" onClick={() => selecionarDespesa(null)} >
                                <i className="bi bi-plus"></i>
                            </button>
                        </h5>
                    </div>
                    <hr />
                    {despesas.length > 0 ?
                        <div className="table-responsive col-12">
                            <table className="table table-striped  table-hover">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Descricao</th>
                                        <th>Pagar</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {despesas.map(despesa => (
                                        <tr key={despesa.id}>
                                            <td>
                                                <small>{moment(despesa.dataHora).format("DD/MM/YYYY HH:MM:ss")}</small>
                                            </td>
                                            <td>
                                                <small>
                                                    {despesa.descricao}
                                                </small>
                                            </td>
                                            <td>
                                                <small>{pagar(despesa)}</small>
                                            </td>
                                            <td>
                                                R${despesa.valor.toFixed(2)}
                                            </td>
                                            <td>
                                                <button className="btn btn-outline-primary" title="Editar despesa" onClick={(e) => selecionarDespesa(despesa)} >
                                                    <i className="bi bi-pencil"></i>
                                                </button>

                                            </td>
                                            <td>
                                                <button className="btn btn-outline-danger" title="Editar despesa" onClick={(e) => deletarDespesa(despesa)} >
                                                    <i className="bi bi-trash"></i>
                                                </button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhuma despesa encontrada.
                        </div>
                    }

                </div>
            </div>
            <Modal show={showModal} onExit={() => loadDespesas()}>
                <ModalBody>
                    <div className="mb-3">
                        <h5>
                            {despesa ? "Editar" : "Nova"} Despesa
                            <button className="btn btn-close float-end" onClick={() => setShowModal(false)}></button>
                        </h5>

                    </div>
                    <FormDespesa onSave={() => setShowModal(false)} despesa={despesa} />
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Despesas;