import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";

import ToastService from "../../services/ToastService";

import { useForm } from 'react-hook-form';

import SelectColaborador from "../inputs/SelectColaborador";
import moment from "moment";

const FormUsuario = ({ onSave, selectedUsuario }) => {

    let loaded = false;

    const [usuario, setCustomer] = useState(selectedUsuario);

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        var currentUsuario = { ...usuario };

        const newRepresentante = { ...currentUsuario, ...data };

        if (!newRepresentante.nascimento)
            newRepresentante.nascimento = null;

        const onError = (error) => {

            console.error(error);

            const message = error?.message || error.title;

            toastService.error("Erro ao salvar dados", message);

        };

        const onSuccess = (response) => {
            toastService.success("Sucesso..", "Dados salvos com sucesso...");

            onSave();
        };

        if (currentUsuario.id > 0) {
            apiPollaris
                .put(`usuarios/${currentUsuario?.id}`, newRepresentante)
                .then(onSuccess)
                .catch(onError);
        } else {

            currentUsuario.ativo = true;

            apiPollaris
                .post(`usuarios`, newRepresentante)
                .then(onSuccess)
                .catch(onError);
        }

    }

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control " {...register('login', { value: usuario?.login, required: true })} />
                    <label>Nome</label>
                </div>
            </div>

            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="email" className="form-control " {...register('email', { value: usuario?.email, required: true })} />
                    <label>Email</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                    <button type="submit" className="btn form-control col-12 btn-success-pollaris btn-lg" >
                        <i className="bi bi-check"></i> Salvar
                    </button>
                </div>
            </div>
        </div>
    </form>
    );
};

export default FormUsuario;