
import moment from "moment";

import { useEffect, useState } from "react";

import PollarisApi from "../../services/PollarisApi";
import { Modal, ModalBody } from "react-bootstrap";
import FormRepresentante from "./FormUsuario";
import ToastService from "../../services/ToastService";
import FormUsuario from "./FormUsuario";

const Usuarios = ({ titulo, categoria }) => {

    let loaded = false;

    const toastService = new ToastService();

    const [loading, setLoading] = useState(false);

    const [dataDe, setDataDe] = useState(moment().format("YYYY-MM-01"));

    const [dataAte, setDataAte] = useState(moment().format("YYYY-MM-DD"));

    const [showModal, setShowModal] = useState(false);

    const [usuario, setRepresentante] = useState(null);

    const [Usuarios, setUsuarios] = useState([]);

    const pollarisApi = new PollarisApi();

    const loadUsuarios = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLoading(true);

        pollarisApi.get("usuarios")
            .then(response => {
                setLoading(false);
                setUsuarios(response);
            }).catch(error => {
                setLoading(false);
                console.log(error);
            }
            );

    }

    const removerRepresentante = (id) => {

        pollarisApi.delete(`usuarios/${id}`)

            .then(response => {

                loadUsuarios();

                toastService.success("Sucesso", "Representante removido ");

            }).catch(error => {

                console.log(error);
            }

            );

    }

    useEffect(() => {

        if (loaded) return;

        loaded = true;

        loadUsuarios();

    }, []);

    const selectRepresentante = (representante) => {

        setRepresentante(representante);

        setShowModal(true);

    }

    return (
        <div className="usuarios">
            <div className="card shadow">
                <div className="card-body">
                    <div>
                        <h5>
                            {titulo ? titulo : "Usuarios"}
                            <button onClick={() => selectRepresentante({})} className="btn float-end">
                                + Novo
                            </button>
                        </h5>
                        <span className="dropstart float-end d-none">
                            <span data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                <i className="bi bi-funnel"></i>
                            </span>
                            <form onSubmit={loadUsuarios} className="dropdown-menu p-4">
                                <div className="mb-3">
                                    <label htmlFor="inputDataInicial" className="form-label">Data Inicial</label>
                                    <input type="date" className="form-control" id="inputDataInicial" value={dataDe} onChange={(e) => setDataDe(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputDataFinal" className="form-label">Data Final</label>
                                    <input type="date" className="form-control" id="inputDataFinal" value={dataAte} onChange={(e) => setDataAte(e.target.value)} />
                                </div>
                                {loading ?
                                    <button className="btn btn-success-pollaris col-12" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only"> Carregando...</span>
                                    </button> :
                                    <button type="submit" className="btn btn-success-pollaris col-12"><i className="bi bi-funnel"></i> Filtrar</button>
                                }
                            </form>
                        </span>

                    </div>

                    <hr />
                    {Usuarios.length > 0 ?
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Login</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Usuarios.map(usuario => (
                                        <tr key={usuario.id}>
                                            <td>{usuario.id}</td>
                                            <td>{usuario.login}</td>
                                            <td>{usuario.email}</td>
                                            <td>
                                                <button onClick={() => selectRepresentante(usuario)} className="btn btn-outline-primary btn-sm">
                                                    <i className="bi bi-pencil"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => removerRepresentante(usuario.id)} className="btn btn-outline-danger btn-sm">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div> :
                        <div className="alert alert-secondary">
                            <i className="bi bi-info-circle"></i> Nenhum representante encontrado.
                        </div>
                    }

                </div>
            </div>

            <Modal show={showModal} onExit={() => loadUsuarios()} dialogClassName="modal-lg">
                <ModalBody>
                    <div className="mb-3">
                        <h5>
                            {usuario ? "Editar" : "Novo"} usuário
                            <button className="btn btn-close float-end" onClick={() => setShowModal(false)}></button>
                        </h5>

                    </div>
                    <FormUsuario onSave={() => setShowModal(false)} selectedUsuario={usuario} />
                </ModalBody>
            </Modal>

        </div>
    );

}

export default Usuarios;