import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = (props) => {

    const usuario = JSON.parse(localStorage.getItem('authentication'));


    const logout = () => {

        localStorage.removeItem('authentication');

        window.location.href = '/';

    }


    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top bg-pollaris navbar-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Pollaris ERP</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                    Cadastros
                                </button>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/clientes">Cliente</Link></li>
                                    <li><Link className="dropdown-item" to="/despesas">Despesa</Link></li>
                                    <li><Link className="dropdown-item" to="/produtos">Produto</Link></li>
                                    <li><Link className="dropdown-item" to="/representantes">Representantes</Link></li>
                                    <li><Link className="dropdown-item" to="/usuarios">Usuários</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/cobrancas">Cobrancas</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/prospectados">Prospectados</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            <ThemeButton />
                            <li className="nav-item dropdown">
                                <button className="nav-link dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                    {usuario.customer.login}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><button className="dropdown-item text-danger" onClick={() => logout()}>Sair</button></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div style={{ marginBottom: "75px" }}></div>
        </div>
    )

}


const ThemeButton = () => {

    const [theme, setTheme] = useState('light');

    const aplyTheme = (selectedTheme) => {
        if (selectedTheme === 'dark') {
            localStorage.setItem('theme', 'dark');
            setTheme('dark');
            document.body.setAttribute('data-bs-theme', 'dark');
        }
        else {
            localStorage.setItem('theme', 'light');
            setTheme('light');
            document.body.setAttribute('data-bs-theme', 'light');
        }
    };

    const toggleTheme = () => {

        const newTheme = theme === 'light' ? 'dark' : 'light';

        console.log(newTheme);

        aplyTheme(newTheme);

        setTheme(newTheme);
    }

    useEffect(() => {
        const localTheme = localStorage.getItem('theme');

        localTheme && setTheme(localTheme);

        aplyTheme(localTheme);
    }, []);

    return (
        <li className="nav-item">
            <button className="nav-link btn btn-link" onClick={toggleTheme}>
                {theme === 'light' ? <i className="bi bi-moon"></i> : <i className="bi bi-brightness-high"></i>}
            </button>
        </li>
    );
}

export default NavBar;

