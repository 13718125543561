
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import { useState } from "react";

import NavBar from "../components/NavBar";
import PageHome from "./PageHome";
import PageRecuperarSenha from "./PageRecuperarSenha";
import PageLogin from "./PageLogin";
import PageClientes from "./PageClientes";
import PageCliente from "./Cadastros/PageCliente";
import PageCobranca from "./PageCobranca";
import PageDespesas from "./PageDespesas";
import PollarisApi from "../services/PollarisApi";
import PageProspectados from "./PageProspectados";
import PageRepresentantes from "./PageRepresentantes";
import PageCobrancas from "./PageCobrancas";
import PageProdutos from "./PageProdutos";
import PageUsuarios from "./PageUsuarios";

function RoutesApp() {

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    useState(() => {

        const authJson = localStorage.getItem("authentication");

        if (authJson) {

            var auth = JSON.parse(authJson);

            const expired = new Date(auth.expire);

            const now = new Date();

            if (now > expired) {

                if (auth.keep) {

                    setIsUserAuthenticated(true);

                    const apiPollaris = new PollarisApi();

                    apiPollaris
                        .authenticate(auth.base64Auth)
                        .then((response) => {

                            response.base64Auth = auth.base64Auth;

                            response.keep = auth.keep;

                            localStorage.setItem("authentication", JSON.stringify(response));
                        })
                        .catch((error) => {

                            localStorage.removeItem("authentication");

                            setIsUserAuthenticated(false);

                        });

                }

            } else {

                setIsUserAuthenticated(true);

            }
        }

    });



    return (
        <BrowserRouter>

            {isUserAuthenticated ? (
                <div>
                    <NavBar />
                    <Routes>
                        <Route path="/login" element={<Navigate replace to="/" />} />
                        <Route exact path="/" element={<PageHome />} />
                        <Route exact path="/cobranca/:paymentId" element={<PageCobranca />} />
                        <Route exact path="/clientes" element={<PageClientes />} />
                        <Route exact path="/produtos" element={<PageProdutos />} />
                        <Route exact path="/representantes" element={<PageRepresentantes />} />
                        <Route exact path="/despesas" element={<PageDespesas />} />
                        <Route exact path="/cobrancas" element={<PageCobrancas />} />
                        <Route exact path="/usuarios" element={<PageUsuarios />} />
                        <Route exact path="/prospectados" element={<PageProspectados />} />
                        <Route exact path="/clientes/:id" element={<PageCliente />} />
                    </Routes>
                </div>
            ) : (
                <Routes>
                    <Route path="*" element={<Navigate replace to="/login" />} />
                    <Route exact path="/recuperar" element={<PageRecuperarSenha />} />
                    <Route exact path="/recuperar/:recoveryCodeParam" element={<PageRecuperarSenha />} />
                    <Route exact path="/login" element={<PageLogin />} />
                </Routes>
            )}
        </BrowserRouter >
    )
}

export default RoutesApp;